import { Box } from '@mui/material';
import GroupsSkeleton from '__mocks__/skeletonLoading/groupsLoader';
import { IMyGroups, IMyGroupsContent } from 'models/groups';
import AvatarImg from 'pages/components/avatarImg';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosPostGroupsList } from 'services/api';
import { colors } from 'styles/colors';
import {  groupImgUrl } from 'utilities/userImageURL';

const GroupList = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(true);
  const [groupsData, setGroupsData] = useState<IMyGroupsContent[]>([]);
  const [pageChange, setPageChange] = useState({
    previousPageCursor: '',
    nextPageCursor: '',
    nextPage: true
  });

  const userId = params?.userId;

  const userPosts = async () => {
    if (pageChange?.nextPage) {
      setLoader(true);
      const payload = {
        size: 20,
        nextPageCursor: pageChange?.nextPageCursor ?? ''
      };
      await axiosPostGroupsList(payload, userId)
        .then((res: IMyGroups) => {
          if (res) {
            setPageChange({
              previousPageCursor: res.previousPageCursor,
              nextPageCursor: res.nextPageCursor,
              nextPage: res.nextPageCursor ? true : false
            });
            setGroupsData((pv) => [...pv, ...res.content]);
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    userPosts();
  }, []);

  return (
    <>
      {loader && groupsData.length === 0 ? (
        <GroupsSkeleton showSubLine={1} />
      ) : (
        groupsData && (
          <>
            <InfiniteScroll
              dataLength={groupsData?.length}
              next={userPosts}
              hasMore={pageChange?.nextPage}
              loader={loader && <GroupsSkeleton showSubLine={1} />}
              scrollableTarget='scrollToElement'
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 16
              }}
            >
              {groupsData?.map((item, index) => {
                return (
                  <Box
                    className='flexCenter'
                    justifyContent={'space-between'}
                    p={2}
                    key={`${item?.id}_${index}`}
                    sx={{
                      borderBottom: `1px solid ${colors.border.primary}`,
                      ':hover': { cursor: 'pointer' }
                    }}
                  >
                    <Box
                      className='flexCenter'
                      onClick={() => navigate(`/group/${item.id}`)}
                    >
                      <AvatarImg
                        id={`userImg-${item?.id}`}
                        enableLetterImg={item?.groupImageDto?.id ? false : true}
                        alt={item?.name}
                        src={
                          item?.groupImageDto?.id
                            ? groupImgUrl(item?.groupImageDto?.id)
                            : ''
                        }
                        name={item?.name}
                        width={34}
                        height={34}
                        nameStyle={{ fontSize: 12 }}
                        style={{
                          width: 34,
                          height: 34,
                          objectFit: 'cover',
                          borderRadius: '50%'
                        }}
                      />

                      <TypographyWithI18N
                        className='smallHeaderBold'
                        i18nKey={item?.name}
                        sx={{
                          color: colors.text.primaryBlack,
                          lineHeight: 24,
                          marginLeft: 2,
                          maxWidth: '85%'
                        }}
                      />
                    </Box>
                    {/* <Box width={150} textAlign={'right'}>
                  <AdminIconButton
                    id='settings'
                    aria-label='more'
                    icon={<MoreDotIcon />}
                    onClick={() => {}}
                  />
                  <AdminIconButton
                    id='settings'
                    aria-label='more'
                    icon={<CloseRoundIcon />}
                    onClick={() => {}}
                  />
                </Box> */}
                  </Box>
                );
              })}
            </InfiniteScroll>
            {!loader && groupsData.length === 0 && (
              <Box sx={{ margin: 'auto', paddingTop: '15%' }}>
                <>
                  <TypographyWithI18N
                    className='smallHeader'
                    py={3}
                    textAlign={'center'}
                    i18nKey={'No Groups'}
                  />
                </>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};

export default GroupList;
