import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SideBar from './sidebar';
import Header from './header';
import React from 'react';
import { colors } from 'styles/colors';
import RightSidePanel from './rightSidePanel';

const Layout = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          background: colors.background.white,
          height: '100%',
          width: '100%'
        }}
      >
        <Box
          sx={{
            width: { xs: '20%', md: '25%' },
            height: '100vh',
            overflow: { md: 'hidden' },
            position: 'sticky',
            top: 0
          }}
        >
          <SideBar />
        </Box>
        <Box
          flex={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderLeft: `1px solid ${colors.border.primary}`,
            borderRight: `1px solid ${colors.border.primary}`
          }}
        >
          <Box
            sx={{
              borderBottom: `1px solid ${colors.border.primary}`,
              height: 70,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 3,
              position: 'sticky',
              top: 0,
              zIndex: 999,
              background: colors.background.white
            }}
          >
            <Header />
          </Box>

          <Box
            id='scrollToElement'
            sx={{
              height: 'calc(100vh - 71px)',
              overflow: 'auto'
            }}
          >
            <Outlet />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: '30%', md: '25%' },
            height: '100vh',
            px: 2
          }}
        >
          <RightSidePanel />
        </Box>
      </Box>
    </>
  );
};

export default Layout;
